exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.43.34\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".VideoContentBlock__Spinner_spinner{border-radius:50%;display:block;height:2em;overflow:hidden;position:relative;width:2em}.VideoContentBlock__Spinner_spinner:before{border:solid .3em var(--adk-theme-hyperlink_FontColor);opacity:.3}.VideoContentBlock__Spinner_spinner:after,.VideoContentBlock__Spinner_spinner:before{border-radius:50%;box-sizing:border-box;content:\"\";display:block;height:100%;width:100%}.VideoContentBlock__Spinner_spinner:after{-webkit-animation:VideoContentBlock__Spinner_spinner 1.2s ease-in-out infinite;animation:VideoContentBlock__Spinner_spinner 1.2s ease-in-out infinite;border-color:var(--adk-theme-hyperlink_FontColor) transparent transparent transparent;border-style:solid;border-width:.3em;left:0;position:absolute;top:0}@-webkit-keyframes VideoContentBlock__Spinner_spinner{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes VideoContentBlock__Spinner_spinner{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);

// exports
exports.locals = {
	"spinner": "VideoContentBlock__Spinner_spinner"
};